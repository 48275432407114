<template>
  <div>
    <div v-if="!formVisible" class="view-wrap">
      <!-- <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="请输入编号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searcForm.checkPlace"
              style="width: 100%"
              multiple
              filterable
              clearable
              placeholder="请选择检查位置"
            >
              <el-option
                v-for="(item, Index) in locationList"
                :key="Index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="业务日期">
            <el-date-picker
              v-model="searcForm.date"
              type="daterange"
              range-separator="至"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
            <el-button type="default" plain @click="reset"> 清空</el-button>
           
          </el-form-item>
        </el-form>
      </div> -->
      <div class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
          @selectChange="selectChange"
        >
          <div slot="operation" slot-scope="row">
            <el-button
              v-if="row.data.invoiceStatus == '2'"
              type="text"
              @click="openInvoiceDialog(row.data)"
              >申请发票</el-button
            >
            <el-button
              v-if="row.data.invoiceStatus != '2'"
              type="text"
              @click="watchInvoice(row.data)"
              >查看发票</el-button
            >
            <el-button
              v-if="row.data.invoiceStatus == '1'"
              type="text"
              @click="download(row.data)"
              >下载发票</el-button
            >
            <!-- <el-button type="text" @click="EditData(row.data)">修改</el-button>
            <el-button type="text" @click="DeleteData(row.data)"
              >删除</el-button
            >
            <el-button type="text" @click="exportData(row.data.id)"
              >导出</el-button
            > -->
          </div>
        </SimpleTable>
      </div>
    </div>
    <!-- <safeLedgerForm ref="editForm" v-if="formVisible" @back="backList" /> -->
    <miaDialog ref="invoiceForm" v-if="invoiceVisible">
      <div slot="content" style="width: 90%; margin: 0 auto">
        <el-form :model="invoiceForm" label-width="130px" ref="invoiceForm">
          <el-form-item label="抬头类型">
            <el-radio-group
              v-model="invoiceForm.invoiceType"
              :disabled="invoiceView"
            >
              <el-radio label="个人/非企业单位">个人/非企业单位</el-radio>
              <el-radio label="企业单位">企业单位</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发票抬头" required>
            <el-input
              v-model="invoiceForm.invoiceLookUp"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="invoiceForm.invoiceType == '企业单位'"
            label="单位税号"
            required
          >
            <el-input
              v-model="invoiceForm.invoiceTaxNo"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="invoiceForm.invoiceType == '企业单位'"
            label="注册地址"
          >
            <el-input
              v-model="invoiceForm.invoiceAddress"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="invoiceForm.invoiceType == '企业单位'"
            label="注册电话"
          >
            <el-input
              v-model="invoiceForm.invoicePhone"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="invoiceForm.invoiceType == '企业单位'"
            label="开户银行"
          >
            <el-input
              v-model="invoiceForm.invoiceBank"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="invoiceForm.invoiceType == '企业单位'"
            label="银行账号"
          >
            <el-input
              v-model="invoiceForm.invoiceBankNo"
              :disabled="invoiceView"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!invoiceView">
            <el-button type="default" @click="closeInvoice">取消</el-button>
            <el-button type="primary" @click="createInvoice">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import { getList, createInvoice } from "@/api/buyRecord";
// import safeLedgerForm from "./form.vue";
import { imgUrl } from "../../../../settting";
export default {
  components: {},
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        date: [],
        param: "",
      },
      invoiceVisible: false,
      UpdateType: null,
      tableData: [],
      total: 0,
      tableItem: [
        { prop: "body", label: "套餐名称", type: "default" },
        { prop: "reqSn", label: "订单号", type: "default" },
        { prop: "prepaidPrice", label: "订单金额", type: "default" },
        { prop: "createDate", label: "支付时间", type: "default" },
        { prop: "payTypeLabel", label: "支付方式", type: "default" },
        { prop: "mailAddress", label: "收货信息", type: "default" },
        { prop: "mailStatusLabel", label: "发货状态", type: "default" },
        { prop: "invoiceStatusLabel", label: "开票状态", type: "default" },
        { label: "操作", type: "operation" },
      ],
      locationList: [
        {
          name: "门店",
          id: "门店",
        },
        {
          name: "仓库",
          id: "仓库",
        },
        {
          name: "门店周边",
          id: "门店周边",
        },
        {
          name: "仓库周边",
          id: "仓库周边",
        },
      ],
      Visible: false,
      formVisible: false,
      checkList: [], // 列表已选项
      invoiceType: "0",
      invoiceForm: { invoiceType: "个人/非企业单位" },
      currentInvoiceId: "",
      invoiceView: false,
    };
  },
  activated() {
    // this.GetCustomerType();
    this.getData();
  },
  methods: {
    WatchData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, true);
      });
    },
    add() {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(null, false);
      });
    },
    // 获取客户类别
    GetCustomerType() {
      GetCustomerTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          // 默认选中
          this.getData();
        } else {
          this.typeList = [];
          this.$message.error("未获取到客户类别");
        }
      });
    },
    selectChange(val) {
      this.checkList = val;
    },
    backList() {
      this.formVisible = false;
      this.getData();
    },
    openInvoiceDialog(data) {
      this.invoiceView = false;
      this.currentInvoiceId = data.id;
      this.invoiceVisible = true;
      this.$nextTick(() => {
        this.$refs.invoiceForm.init("开票信息");
      });
    },
    watchInvoice(data) {
      this.invoiceView = true;
      this.invoiceVisible = true;
      this.invoiceForm = {
        invoiceAddress: data.invoiceAddress,
        invoiceBank: data.invoiceBank,
        invoiceBankNo: data.invoiceBankNo,
        invoiceLookUp: data.invoiceLookUp,
        invoicePhone: data.invoicePhone,
        invoiceStatus: data.invoiceStatus,
        invoiceTaxNo: data.invoiceTaxNo,
        invoiceType: data.invoiceType,
      };
      this.$nextTick(() => {
        this.$refs.invoiceForm.init("开票信息");
      });
    },
    download(data) {
      window.open(imgUrl + data.invoiceImg);
    },
    getData() {
      const params = { ...this.searcForm };

      // if (params.date) {
      //   params.startDate = params.date[0];
      //   params.endDate = params.date[1];
      // }
      getList(params).then((res) => {
        if (res.code === 1) {
          const { records, current, size, total } = res.data;
          this.tableData = records.map((val) => {
            val.prepaidPrice = val.prepaidPrice / 100;
            val.payTypeLabel = val.payType == "0" ? "微信" : "支付宝";
            val.mailStatusLabel =
              val.mailStatus == "0"
                ? "待发货"
                : val.mailStatus == "1"
                ? "已发货"
                : "-";
            val.invoiceStatusLabel =
              val.invoiceStatus == "0"
                ? "开票中"
                : val.invoiceStatus == "1"
                ? "已开票"
                : "-";
            return val;
          });
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/data/customerForm" });
      this.AddVisible = true;
    },
    // 修改
    EditData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, false);
      });
      // this.$router.push({ path: "/data/EditCustomer", query: { id: data.id } });
    },
    refresh() {
      this.getData();
    },
    exportData(id) {
      // 导出
      safeLedgerExport(id).then((data) => {
        const fileName = data.fileName || "农药安全管理和安全防护记录.docx";
        //   res.data:请求到的二进制数据
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //1.创建一个blob
        const link = document.createElement("a"); //2.创建一个a链接
        link.download = fileName; //3.设置名称
        link.style.display = "none"; // 4.默认不显示
        link.href = URL.createObjectURL(blob); // 5.设置a链接href
        document.body.appendChild(link); //6.将a链接dom插入当前html中
        link.click(); //7.点击事件
        URL.revokeObjectURL(link.href); //8.释放url对象
        document.body.removeChild(link); //9.移除a链接dom
      });
    },
    deleteBatch() {
      const ids = this.checkList.map((v) => {
        return v.id;
      });
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delSafeLedger(ids.join(",")).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delSafeLedger(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    }, // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 客户期初设置
    setting(data) {
      this.submitForm.customerId = data.id;
      this.submitForm.initialPayment = undefined;
      this.submitForm.initialAdvancePayment = undefined;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("客户期初设置");
      });
    },
    // 客户期初设置提交
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitForm.initialPayment = +this.submitForm.initialPayment;
          this.submitForm.initialAdvancePayment =
            +this.submitForm.initialAdvancePayment;
          SettingCustomerInitial(this.submitForm).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.close();
              this.getData();
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    reset() {
      this.searcForm = {
        date: "",
        param: "",
        checkPlace: [],
      };
    },
    closeInvoice() {
      this.invoiceVisible = false;
      // this.$refs.invoiceForm.close();
    },
    createInvoice() {
      if (!this.invoiceForm.invoiceLookUp) {
        return this.$message.error("请填写发票抬头");
      }
      if (
        this.invoiceForm.invoiceType == "企业单位" &&
        !this.invoiceForm.invoiceTaxNo
      ) {
        return this.$message.error("请填写单位税号");
      }
      createInvoice({ id: this.currentInvoiceId, ...this.invoiceForm }).then(
        (res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.closeInvoice();
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
  },
};
</script>
