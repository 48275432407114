import request from '@/until/request.js'
const prefixName = '/order/webPayRecord'

// 获取账户列表
export function getList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}

export function createInvoice(data) {
    return request({
      url: prefixName,
      method: 'put',
      data
    })
  }